import { defaultProducts } from "~/json/products-dummy.json";
import type { Product } from "~/types/ecom/product/product.type";

export const useCompareStore = defineStore("product-compare", () => {
  const productsForComparison = ref<string[]>([
    "11665",
    "11678",
    "11794",
    "13548",
    "13772",
  ]);

  const checkedProduts = ref<Product[]>([]);
  const enableExpand = ref<boolean>(false);
  const isCompareRowVisible = ref<boolean>(false);
  const isSpecificationEnable = ref<boolean>(false);

  const addToCompareSearchQuery = ref("");
  const isDifferenceOnlyEnabled = ref(false);
  const freezedProductCode = ref<string>();
  const comparableProperties = ref<string[]>(["Technical specifications"]);
  const isFreezed = ref<boolean>(true);

  function getProductsForComparison(
    codes: string[] = productsForComparison.value
  ): Product[] | undefined {
    if (codes.length > 6) return undefined;

    // TODO: ecom API fetch
    return defaultProducts.filter((p) => codes.includes(p.code));
  }

  function setProductsForComparison(codes: string[]) {
    productsForComparison.value = codes;
  }

  function toggleFreeze(productCode: string | undefined) {
    if (freezedProductCode.value && freezedProductCode.value === productCode) {
      freezedProductCode.value = undefined;
      return;
    }
    freezedProductCode.value = productCode;
  }

  function clearCompareProducts() {
    checkedProduts.value = [];
    isCompareRowVisible.value = false;
  }
  
  function removeCompareProduct(product: Product) {
    checkedProduts.value = checkedProduts.value.filter(p => p.code !== product.code);
    if(!checkedProduts.value.length) isCompareRowVisible.value = false;
  }

  return {
    productsForComparison,
    comparableProperties,
    addToCompareSearchQuery,
    isDifferenceOnlyEnabled,
    freezedProductCode,
    getProductsForComparison,
    setProductsForComparison,
    toggleFreeze,
    checkedProduts,
    clearCompareProducts,
    removeCompareProduct,
    enableExpand,
    isCompareRowVisible,
    isSpecificationEnable,
    isFreezed
  };
});


// Pinia hot reload
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCompareStore, import.meta.hot))
  }
